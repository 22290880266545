export const ANCHORS: SectionAnchor[] = [
  'home',
  // 'takeaway',
  'about',
  'book-and-menu',
  'private-dining',
  'gift-vouchers',
  'careers',
  'contact',
];

export const MENU_LIST: {
  label: string;
  section: SectionAnchor | SectionSlideAnchor;
}[] = [
  { label: 'About', section: 'about' },
  { label: 'BOOK', section: 'book-and-menu' },
  { label: 'MENU', section: 'book-and-menu/menu' },
  { label: 'SET MENU', section: 'book-and-menu/set-menu' },
  { label: 'PRIVATE DINING', section: 'private-dining' },
  { label: 'GIFT VOUCHERS', section: 'gift-vouchers' },
  { label: 'CAREERS', section: 'careers' },
  { label: 'CONTACT', section: 'contact' },
  // { label: 'Takeaway', section: 'takeaway' },
];

export const FB_PAGE_URL =
  'https://www.facebook.com/Bi%C3%A0nca-Restaurant-108307921336092';

export const INS_PAGE_URL = 'https://www.instagram.com/bianca.restaurant/';

export const GOOGLE_MAP_URL = 'https://goo.gl/maps/891mytF5U9TS1j387';

export const HARRIS_WEBSITE_URL = 'http://www.subtitleddesign.com/';
