import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import ogImage from 'images/BIANCA_OG_IMAGE.png';

type Props = { title: string; description?: string; lang?: string };

const SEO: React.FC<Props> = ({ title, description = '', lang = 'en' }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  const metaTitle =
    title === 'Home' || title === '404'
      ? site.siteMetadata.title
      : `${title} | BIÀNCA`;
  const metaDescription = description || site.siteMetadata.description;
  const { siteUrl } = site.siteMetadata;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defer={false}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: `Biànca, Bianca, Biànca Restaurant, Italian Restaurant, Italian, James Street, Brisbane, Queensland`,
        },
        {
          name: `image`,
          content: ogImage,
        },
        {
          property: `og:url`,
          content: siteUrl,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:image:width`,
          content: '1200',
        },
        {
          property: `og:image:height`,
          content: '627',
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: ogImage,
        },
      ]}
    />
  );
};

export default SEO;
