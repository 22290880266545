import React, { Fragment, useContext } from 'react';

import { preset, colors } from 'styles';
import { SidebarContext } from 'context';
import { HARRIS_WEBSITE_URL } from 'constant';

import leftSideImg from 'images/left-side-border.png';
import rightSideImg from 'images/right-side-border.png';
import WebsiteCredit from 'assets/credit.svg';

type Props = { location?: Location };

const SideBorders: React.FC<Props> = ({ location }) => {
  const hash = location?.hash;

  const { open } = useContext(SidebarContext);

  const isContactSection = hash === '#contact';

  return (
    <Fragment>
      <div
        id="left-sider"
        css={{
          position: 'fixed',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 1201,
          width: preset.spacing(2),
          backgroundImage: `url(${leftSideImg})`,
          backgroundSize: 'contain',
          transition: preset.TRANSITION,
          [preset.breakpoints.md]: {
            zIndex: 2,
            width: preset.spacing(3),
          },
        }}
      />
      <div
        id="right-sider"
        css={{
          position: 'fixed',
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: 1201,
          width: preset.spacing(2),
          backgroundImage: `url(${rightSideImg})`,
          backgroundSize: 'contain',
          transition: preset.TRANSITION,
          [preset.breakpoints.sm]: {
            ...(open
              ? {
                  right: preset.SIDEBAR_WIDTH.lg,
                  [preset.breakpoints.xxl]: {
                    right: preset.SIDEBAR_WIDTH.xl,
                  },
                }
              : { right: 0 }),
          },
          [preset.breakpoints.md]: {
            width: preset.spacing(3),
            zIndex: 2,
          },
        }}
      />

      <a
        href={HARRIS_WEBSITE_URL}
        target="_blank"
        rel="noreferrer"
        css={{
          display: 'none',
          position: 'fixed',
          right: 0,
          bottom: preset.spacing(1),
          width: preset.spacing(3),
          height: '30vh',
          backgroundColor: colors.WHITE,
          transition: preset.TRANSITION,
          ...(isContactSection
            ? { opacity: 1, zIndex: 3 }
            : { opacity: 0, zIndex: -1, pointerEvents: 'none' }),
          [preset.breakpoints.md]: { display: 'block' },
        }}>
        <WebsiteCredit width="100%" height="100%" />
      </a>
    </Fragment>
  );
};

export default SideBorders;
